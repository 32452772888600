.main-wrapper{
	padding: 0;
	min-width: $min-width;
    width: 100%;
	position: relative;
	overflow: hidden;
	min-height:100%;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction:column;
    -webkit-flex-direction:column;
    -ms-flex-direction: column;
	justify-content:flex-start;	
    -webkit-justify-content:flex-start;	
    -ms-flex-pack: start;
}
.container{
	min-width: $min-width;
	max-width: $max-width;
	padding: 0 40px;
	margin: 0 auto;
	position: relative;
}

/* titles */
p{	
	padding: 15px 0 15px 0;
	font-weight: 400;	
}
h1, .h1{	
	font-weight: 900;
	font-family: $custom-font-family;
	font-size: 48px;
	padding: 15px 0 15px 0;
	text-align: center;
}
h2, .h2{
	font-family: $custom-font-family;
	color: $title-color;
	font-weight: 900;
	font-size: 48px;
	padding: 15px 0 40px 0;
	text-align: center;
}
h3{
	font-family: $custom-font-family;
	font-size: 36px;
	padding: 15px 0 15px 0;
	font-weight: 900;
	text-align: center;
}

/* text position */
.text-left{text-align: left!important}
.text-center{text-align: center!important}
.text-right{text-align: right!important}
.nowrap{white-space: nowrap!important;}


/* loader */
.loaded .main-wrapper{visibility:hidden;}
.icon-load{background:url(../img/loader.gif) no-repeat left top;width:40px;height:40px;position:fixed;left:50%;top:50%;margin-left:-20px;margin-left:-20px;display:none;}
.loaded .icon-load{display:block;}


/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content{	
	padding-bottom: 85px;
	min-width: $min-width;
	text-align:left;
    width: 100%;
	order: 2;
	flex-grow: 1;
}
.btn {
	border: 2px solid $color-active;
	color: #fff;
	display: inline-block;
	font-family: $custom-font-family;
	height: 55px;
	line-height: 51px;
	background: $color-active;
	border-radius: 129px;
	 font-weight: 500;
	font-size: 16px;
	min-width: 264px;
	text-align: center;
	padding: 0 15px;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
	transition: background .3s ease-in, color .3s ease-in;
	&:hover {
		background: transparent;
		color: $color-active;
	}
}
.btn2 {
	background: transparent;
	color: $color-active;
	&:hover {
		background: $color-active;
		color: #fff;
	}
}
.section-main {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	color: #fff;
	min-height: 100vh;
	font-family: $custom-font-family;
	display: table;
	padding: 170px 0 205px;
	position: relative;
	width: 100%;
	.container {
		display: table-cell;
		height: 100%;
		text-align: center;
		vertical-align: middle;
		z-index: 1
	}
	.h1 {
		color: inherit;
		padding: 0
	}
}
.main-block {
	max-width: 1000px;
	margin: 0 auto;
	&__subtitle {
		font-weight: 900;
		font-size: 25px;
		margin-bottom: 30px;
	}
	&__date {
		font-weight: 800;
		font-size: 25px;
		margin-top: 10px;
	}
	&__links {
		margin-top: 83px;
		.btn {
			box-shadow: none
		}
	}
	&__time {
		font-size: 16px;
		margin-top: 5px
	}
	.btn:hover {
		color: #fff;
	}
	.btn2 {
		color: #fff;
	}
}
.block-links_item {
	display: inline-block;
	margin: 0 15px 30px;
}
.video-bg {
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    overflow: hidden;
    video {
        height: 100%;
        position: relative;
        top: 50%;
        transform: translate(0,-50%);
        width: 100%;
        z-index: 0;
    }
    &:before {
        content: '';
        background: rgba(#0a131f, .78);
        bottom: 0;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1
    }
}
.scroll-down {
	background: url(../img/icons/mouse-scroll.svg) center center no-repeat;
	background-size: contain;
	display: block;
	height: 41px;
	width: 20px;
	position: absolute;
	left: 50%;
	transform: translate(-50%,0);
	bottom: 15vh;
    animation: infinite;
    animation-name: mouse;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    z-index: 1
}

    @keyframes mouse {
      0% {
        bottom: 50px;
      }
      50% {
        bottom: 30px;
      }
      100% {
        bottom: 50px;
      }
    }



.section-experience {
    padding-top: 108px;
}
.reviews-thumb {
	margin-top: 30px;
	padding: 0 80px;
	position: relative;
	&__item {
		text-align: center;
		img {
			cursor: pointer;
		}
	}
	&__avatar {
		max-width: 53px;
		max-height: 53px;
		border-radius: 50%
	}
	&__title {
		cursor: pointer;
		font-size: 16px;
		color: rgba(#606060, .4);
		opacity: 0.4;
		font-weight: 700;
		margin-top: 12px;
	}
	.slick-arrow {
		position: absolute;
		top: 50%;
		margin-top: -24px;
	}
	.slick-prev {
		left: 7px;
	}
	.slick-next {
		right: 7px;
	}
}

.review-wrap {
	display: flex;
}
.reviews-slider {
	.slick-list {
		overflow: visible;
	}
}
.reviews {
	background: #FFF;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
    padding: 68px 67px;
	margin-top: 116px;
	margin-right: 100px;
	&-slider {
		margin-right: -100px;
	}
}
.review {
	&__description {
		padding-right: 30px;
		width: 61.66%
	}
	&__picture {
		position: relative;
		width: 38.33%;
	}
	&__img {
		display: block;
		margin-top: -137px;
		margin-right: -72px;
		transform: translate(-3px,0);
		img {
		display: block;
		max-width: 401px;

		}
	}
	&__top {
		display: flex;
		justify-content: space-between;
	}
	&__rating {
		color: $color-active;
		margin-left: -3px;
		i {
			font-size: 23px;
			margin-right: 8px;
		}
	}
	&__date {
		color: $color-active;
		line-height: 21px;
		font-size: 14px;
		margin-right: 6px;
	}
	&__social {
		color: $color-active;
		font-size: 20px;
		margin-left: 10px;
		i {
			font-size: 21px
		}
	}
	&__ratingvalue {
		font-family: $custom-font-family;
		font-weight: bold;
		font-size: 24px
	}
	&__author {
		font-family: $custom-font-family;
		font-weight: 800;
		font-size: 24px;
		margin-top: 21px;
	}
	&__text {
		line-height: 25px;
		font-size: 14px;
		color: #606060;	
		padding-bottom: 35px;
		p {
			padding-top: 11px
		}	
	}
}
.reviews-thumb__item.slick-current .reviews-thumb__title {
	color: $color-active
}
.arrows-slider {
	margin-top: -40px;
}


.section-where {
	padding-top: 106px
}
.where {
	display: flex;
	margin: 98px 0 0;
	&__item {
		width: 100%;
		max-width: 980px;
		margin: 0 auto;
	}
	&__title {
		font-family: $custom-font-family;
		font-weight: 800;
		font-size: 24px;
		text-align: center;
		margin-top: 70px;
	}
	&__img {
		position: relative;
		text-align: center;
		z-index: 1;
		img {
			vertical-align: top;
		}
	}
	&__picture {
		margin: 0 auto;
		position: relative;
	}
	&_info {
		margin: 50px auto 0;
		font-size: 18px;
		font-weight: 300;
		line-height: 1.4;
		max-width: 800px;		
		text-align: center;
	}
	&__details {
		padding-right: 20px;
		text-align: left;
	}
	&__logo {
		padding-left: 20px
	}
	&__heading {
		background: linear-gradient(142.83deg, #429CEF 6.72%, #03C3FF 93.19%);
		color: #fff;
		display: flex;
		font-family: $custom-font-family;
		padding: 20px 20px 17px;
		font-size: 30px;
		font-weight: 700;
		line-height: 1.15;
		justify-content: space-between;
		align-items: center;
		strong {
			font-weight: 900;
			font-size: 40px;
		}
		span {
			font-size: 18px
		}
	}
}

.section-auditory {
	margin-top: 26px;
	padding-top: 109px
}
.auditory {
	display: flex;
	margin-top: 30px;
	&__item {
		color: #fff;
		position: relative;
		width: 33.333%;
		&-1 {
			background: $color-active
		}
		&-2 {
			overflow: hidden;
			min-height: 320px;
			.auditory__details {
				bottom: 0;
				left: 0;
				right: 0;
				position: absolute;
				padding-left: 49px
			}
			img {
				height: 406px;
				max-width: none;
				position: relative;
				left: 50%;
				transform: translate(-50%,0);
			}
		}

		&-3 {
			background: #D32439
		}
	}
	&__picture {
		position: relative;
		z-index: 0;
		img {
			vertical-align: top;
		}
		&:before {
			content: '';
			position: absolute;
			display: block;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: rgba(#2E343E,.1);
		};
	}
	&__details {
		min-height: 153px;
		padding: 21px 43px;
	}
	&__slogan {
		top: 0;
		left: 0;
		right: 0;
		padding: 7px 33px;
		position: absolute;
		font-weight: bold;
		line-height: 34px;
		font-size: 22px;
	}
	&__title {
		font-family: $custom-font-family;
		font-weight: 800;
		font-size: 24px;
	}
	&__description {
		line-height: 24px;
		font-size: 16px;
		margin-top: 10px;
	}
}


.section-speakers {
	margin-top: 119px;
	padding-top: 109px;
	padding-bottom: 118px;
}
.speakers {
	display: flex;
	margin: 33px -15px 0;
	&__item {
		display: flex;
		justify-content: space-between;
		padding: 0 15px;
		width: 50%;
		&:nth-child(2) {
			.speakers__picture_wrap {
				margin-top: 27px
			}
		}
	}
	&__picture {
		position: relative;
		width: 200px;
		&_wrap {
			margin-left: 48px;
			margin-top: 47px;
		}
		img {
			width: 172px;
			vertical-align: top;
			box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
		}
	}
	&__details {
		padding: 0 0 0 47px;
		width: calc(100% - 248px)
	}
	&__position {
		font-family: $custom-font-family;
		font-weight: 600;
		line-height: 19px;
		font-size: 12px;
		color: #606060;
		margin-top: 4px;
	}
	&__name {
		color: $color-active;
		font-family: $custom-font-family;
		font-weight: 800;
		font-size: 24px;
	}
	&__description {
		line-height: 26px;
		font-size: 16px;
		color: #606060;
		margin-top: 13px;
		p {
			padding: 0
		}
	}
	&__img {
		position: relative;
		z-index: 1
	}
	.socials {
		margin-top: 15px
	}
}
.socials {
	&__link {
		color: $color-active;
		font-size: 18px;
		margin-right: 5px;
	}
}
.anim-block {
    &__inner {
    	transform-style: preserve-3d;
    	> * {transform: translateZ(20px)}
    }
}
.anim-decor {
	position: absolute;
	> * {
		left: 0;
		top: 0;
		position: absolute;
		width: 100%;
		height: 100%;
	}
}

.anim-1 {
	width: 362px;
	height: 242px;
    left: -83px;
    top: -43px;
	div {
		background: #2693EE;
		opacity: 0.2;
	}
}
.anim-2 {
	width: 150px;
	height: 150px;
	left: -25px;
	bottom: -38px;
	div {
		background: rgba(250, 0, 111, 0.2);
	}
}
.anim-3 {
	width: 32px;
	height: 32px;
    right: 60px;
    top: -52px;
	div {
		background: #2693EE;
		opacity: 0.5;
	}
}
.anim-4 {
	width: 68px;
	height: 68px;
    left: -28px;
    top: -32px;
	div {
		background: #2693EE;
		opacity: 0.6;
	}
}
.anim-5 {
	width: 361px;
	height: 228px;
    left: -29px;
    bottom: -61px;
	div {
		background: #2693EE;
		opacity: 0.2;
	}
}
.anim-6 {
	width: 168px;
	height: 168px;
    right: -39px;
    top: 119px;
	div {
		background: #2693EE;
		opacity: 0.1;
	}
}


.anim-7 {
	width: 114px;
	height: 120px;
	left: -49px;
	top: -50px;
	div {
		background: #BB6BD9;
		opacity: 0.2;
	}
}
.anim-8 {
	width: 56px;
	height: 63px;
	left: -23px;
    bottom: -27px;
	div {
		background: #F2C94C;
		opacity: 0.2;
	}
}
.anim-9 {
	width: 26px;
	height: 26px;
	right: -15px;
	top: -40px;
	div {
		background: #2693EE;
		opacity: 0.3;
	}
}
.anim-10 {
	width: 56px;
	height: 63px;
    left: -21px;
    top: -30px;
	div {
		background: #219653;
		opacity: 0.2;
	}
}
.anim-11 {
	width: 114px;
	height: 120px;
    left: -48px;
    bottom: -47px;
	div {
		background: #EB5757;
		opacity: 0.2;
	}
}
.anim-12 {
	width: 26px;
	height: 26px;
    right: -15px;
    top: 70px;
	div {
		background: #2F80ED;
		opacity: 0.3;
	}
}
.anim-13 {
	width: 63px;
	height: 80px;
    left: -33px;
    top: -29px;
	div {
		background: #EB5757;
		opacity: 0.4;
	}
}
.anim-14 {
	width: 118px;
	height: 122px;
    left: -53px;
    bottom: 45px;
	div {
		background: #F2994A;
		opacity: 0.2;
	}
}
.anim-15 {
	width: 38px;
	height: 38px;
	right: -20px;
    top: -20px;
	div {
		background: #2693EE;
		opacity: 0.6;
	}
}
.anim-16 {
	width: 255px;
	height: 255px;
    right: -46px;
    bottom: -39px;
	div {
		background: #2693EE;
		opacity: 0.1;
	}
}

.anim-17 {
	width: 264px;
	height: 277px;
	left: -83px;
	top: -67px;
	div {
		background: #BB6BD9;
		opacity: 0.2;
	}
}
.anim-18 {
    width: 109px;
    height: 110px;
    left: -47px;
    bottom: -41px;
	div {
		background: rgba(#F2994A, 0.2);
	}
}
.anim-19 {
	width: 54px;
	height: 54px;
	right: -82px;
	top: -53px;
	div {
		background: rgba(#219653, 0.2);
	}
}
.section-decor  {
	position: relative;
	&:before {
		content: '';
		background: rgba(196, 196, 196, 0.4);
		display: block;
		height: 2px;
		width: 100%;
		max-width: 1146px;
		position: absolute;
		left: 50%;
		transform: translate(-50%,0);
		top: 0;
	}
}


.section-content-seminar {
    margin-top: 120px;
    padding-top: 109px;
}
.content-seminar {
	display: flex;
	align-items: center;
	margin-top: 30px;
	&__list {
		padding-right: 100px;
		width: 50%;
		position: relative;
		&:before {
			content: '';
			background: #CACCDD;
			display: block;
			width: 2px;
			position: absolute;
			left: 20px;
			top: 10px;
			bottom: 26px;
		}
	}
	&__item {
		position: relative;
		padding: 0 0 0 65px;
		margin-bottom: 23px;
		&:hover {
			.content-seminar {
				&__counter {
					background: $color-active;
					border-color: $color-active;
					color: #fff;
				}
				&__title {
					color: $color-active;;
				}
			}
		}
	}
	&__counter {
		background: #fff;
		width: 40px;
		height: 40px;
		border: 2px solid #CACCDD;
		border-radius: 50%;
		line-height: 38px;
		font-size: 18px;
		color: #CACCDD;
		position: absolute;
		text-align: center;
		left: 0;
		top: 50%;
		transform: translate(0,-50%);
	}
	&__title {
		line-height: 27px;
		font-size: 18px;
		color: #000;
	}
	&__btn {
		padding-left: 67px;
		position: relative;
		margin-top: 55px;
		&:before {
			content: '';
			background: #CACCDD;
			display: block;
			height: 2px;
			width: 46px;
			position: absolute;
			top: 50%;
			margin-top: -1px;
			left: 20px;
		}
	}
	.where__picture {
		padding: 0;
		max-width: 459px;
	}
	.where__img img {
		width: 459px;
	}
}

.where__picture_wrap {	
	padding: 0 0 5px 25px;
	width: 50%;
}
.section-special {
	margin-top: 101px;
    padding-top: 108px;
}
.special {
	display: flex;
	align-items: center;
	margin: 8px -15px 0;
	&__picture, &__details {
		padding: 0 15px;
		width: 50%;
	}
	&__picture {
		text-align: center;
		padding-bottom: 20px;
	}
	&__list {
		list-style: none;
	}
	&__item {
		align-items: center;
		font-family: $custom-font-family;
		font-size: 22px;
		color: #606060;
		border-radius: 10px;
		border: 3px dashed $color-active;
		display: flex;
		margin-bottom: 31px;
		padding: 39px 63px 37px;
		transition: border .3s ease-in, background .3s ease-in, color .3s ease-in;
		&:hover {
			background: $color-active;
			border-style: solid;
			box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.28);
			color: #fff;
		}
	}
	&__description {
		width: calc(100% - 72px)
	}
	&__counter {
		padding-right: 10px;
		width: 72px;
	}
}


.section-order {
	margin-top: 98px;
	.h2 {
		max-width: 765px;
		margin: 0 auto;
		line-height: 1.45
	}
}
.order {
	display: flex;
	justify-content: space-between;
	margin: 25px 0 0;
	background: #FFFFFF;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
	padding: 50px 70px 65px;
	&__heading {
		color: $color-active;
		font-family: $custom-font-family;
		font-weight: 800;
		font-size: 36px;
		margin-bottom: 39px;
	}
	&-list {
		width: 50%;
		padding: 22px 30px 0
	}
}
.practice-list {
	list-style: none;
	&__item {
		line-height: 27px;
		font-size: 18px;
		margin-bottom: 16px;
		position: relative;
		padding-left: 34px;
		&:before {
			@extend .icon-check:before;
			color: $color-active;
			font-family: 'icomoon' !important;
			font-size: 20px;
			margin-right: 20px;
			position: absolute;
			left: 0;
			top: -3px;
		}
	}
}


.form-order {
	width: 50%;
	padding: 0 15px;
	max-width: 400px;
	.box-field_checkbox {
    	margin-top: 47px;
    }
}
.input--nao {
    overflow: hidden;
    padding-top: 1em;
}
.box-field__input {
    position: relative;
    z-index: 1;
    padding: 0 0 20px;
    margin-bottom: 10px;
    overflow: hidden;
    vertical-align: top;
    label.error {
    	color: #D32439;
    	position: absolute;
    	left: 0;
    	right: 0;
    	bottom: 0;
    }
}

.input__field {
    width: 100%;
    color: $text-color;
    font-size: 18px;
    line-height: 27px;
    position: relative;
    display: block;
    padding: 19px 0 8px;
    border: none;
    border-radius: 0;
    background: none;
    font-weight: 400;
    &.error {
    	& ~ .graphic--nao {
    		stroke: #D32439
    	}
    }
	&:focus ~ .graphic--nao {
		stroke: $color-active
	}
}

.input__label{
    position: absolute;
    top: 0.95em;
    left: 0;
    display: block;
    width: 100%;
    text-align: left;
    padding: 0em;
    line-height: 27px;
	font-size: 18px;
    pointer-events: none;
    transform-origin: 0 0;
    transition: transform 0.2s 0.15s, color 1s;
    transition-timing-function: ease-out;
}

.graphic--nao {
    position: absolute;
    bottom: 15px;
    left: 0;
    fill: none;
    stroke: #CACCDD;
    stroke-width: 1.5px;
    pointer-events: none;
    transition: transform 0.7s, stroke 0.7s;
    transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
} 

.input__field:focus + .input__label,
.input--filled .input__label {
    transform: translate3d(0, -15px, 0) scale3d(0.7, 0.7, 1);
}

.input__field:focus ~ .graphic--nao,
.input--filled .graphic {
    transform: translate3d(-66.6%, 0, 0);
}
.box-field_btn {
	margin-top: 31px
}
.box-field_checkbox {
	margin-top:44px;
	position: relative;
}
.checkbox {
	opacity: 0;
	position: absolute;
	left: 0;
	top: 0;
	&:checked + .check-label:after {
		background: $color-active;
	}
}
.check-label {
	color: #606060;
	cursor: pointer;
	display: block;
	font-weight: 300;
	line-height: 18px;
	font-size: 12px;
	position: relative;
	padding-left: 34px;
	&:before, 
	&:after  {
		content: '';
		display: block;
		position: absolute;
	}
	&:before {
		width: 20px;
		height: 20px;
		border: 1px solid $color-active;
		top: 2px;
		left: 0		
	}
	&:after {
		width: 12px;
		height: 12px;
		background: transparent;
		transition: background .3s ease-in;
		top: 6px;
		left: 4px
	}
	a {
		color: inherit;
	}
}
.btn-video {
	margin-top: 40px;
	text-align: center;
}
.section-industries {
	padding: 100px 0 136px;
	.h2 {
		max-width: 880px;
		margin: 0 auto;
	}
	.wrapper {
		z-index: 1
	}
}
.industries-wrap {
	position: relative;
	&:before {
		content: '';
		background: #EEE;
		top: 101px;
		bottom: 124px;
		left: 0;
		right: 0;
		position: absolute;
		z-index: 0
	}
}
.industries {
	display: flex;
	flex-wrap: 33.33%;
	margin: 0 -15px;
	padding: 23px 0 0;
	&__column {
		width: 33.33%;
		padding: 0 15px;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}
	&__item {
		background: $color-active;
		margin-bottom: 51px;
		width: 100%;
			display: flex;
			justify-content: center;
			flex-direction: column;
		&:last-child {
			margin-bottom: 0;
		}
		img {
			vertical-align: top;
			width: 100%
		}
		&_2 {
			.industries__title {
				padding-top: 52px;
				padding-bottom: 53px;
			}
		}
		&_3 {
			height: 100%;
			.industries__title {
				padding-top: 58px;
				padding-bottom: 54px;
			}
		}
		&_4 {
			margin-bottom: 49px;
			.industries__title {
				padding-top: 27px;
				padding-bottom: 28px;
			}
		}
		&_5 {
			.industries__title {
				padding-top: 27px;
				padding-bottom: 27px
			}
		}

	}
	&__title {
		color: #fff;
		font-weight: 800;
		font-size: 24px;
		padding: 25px 38px 24px;
		font-family: $custom-font-family;
				flex-grow: 4;
				width: 100%;
				display: flex;
				align-items: center;
	}
}

.section-clients {
	margin-top: 106px
}
.clients {
	display: flex;
	flex-wrap: wrap;
	margin: 30px -15px 0;
	align-items: center;
	justify-content: center;
	&__item {
		padding: 0 15px;
		margin: 0 0 28px;
		text-align: center;
		width: 25%;
	}
}

.wow {
     opacity: 0
 }
.ios .wow {
    opacity: 1
}
.animated{animation-duration:1s;animation-fill-mode:both}.animated.infinite{animation-iteration-count:infinite}
@keyframes fadeInUp{0%{opacity:0;transform:translate3d(0,50%,0)}to{opacity:1;transform:none}}.fadeInUp{animation-name:fadeInUp}
@keyframes zoomIn{0%{opacity:0.5;transform:scale3d(.8,.8,.8)}50%{opacity:1}}.zoomIn{animation-name:zoomIn}
@keyframes zoomInDown{0%{opacity:0;transform:scale3d(.1,.1,.1) translate3d(0,-1000px,0);animation-timing-function:cubic-bezier(.55,.055,.675,.19)}60%{opacity:1;transform:scale3d(.475,.475,.475) translate3d(0,60px,0);animation-timing-function:cubic-bezier(.175,.885,.32,1)}}
@keyframes fadeInRight{0%{opacity:0;transform:translate3d(100%,0,0)}to{opacity:1;transform:none}}
.fadeInRight{animation-name:fadeInRight}
   



.scrollDisabled { overflow: hidden; position: fixed !important; margin-top: 0; width: 100%; z-index: 0; }

.scrollDisabled.web.compensate-for-scrollbar { width: -webkit-calc(100% - 17px); width: calc(100% - 17px); }

.scrollDisabled.web.compensate-for-scrollbar header { padding-right: 17px; }

.hide-desc {
	display: none;
}

@media screen and (max-width: 1150px) {
	.industries__title {
		font-size: 22px
	}
}

@media screen and (max-width: 1040px){	
	.industries__title {
		font-size: 20px
	}
}

@media screen and (max-width: 991px) {
	.container {
		padding-left: 30px;
		padding-right: 30px
	}
	h1, .h1 {
		font-size: 32px
	}
	h2, .h2 {
		font-size: 30px
	}	
	.main-block__subtitle, .main-block__date {
		font-size: 23px;
	}
	.auditory {
		justify-content: center;
		flex-wrap: wrap;
	}
	.auditory__item {
		max-width: 382px;
		width: 50%;
		&:first-child {
			order: 1;
		}
		&:nth-child(2) {
			order: 3;
		}
		&:nth-child(3) {
			order: 2;
		}
	}
	.order {
		display: block;
	}
	.order-list, .form-order {
		margin: 0 auto;
		max-width: 500px;
		width: 100%;
	}
	.form-order {
		margin-top: 30px
	}
	.box-field_btn {
		text-align: center;
	}
	.special__item {
		padding: 25px 30px 23px;

	}
	.content-seminar__list {
		padding-right: 15px;
	}
	.where__picture {
		padding: 0
	}
	.industries {
		margin-left: -10px;
		margin-right: -10px;
		&__column {
			padding-left: 10px;
			padding-right: 10px;
		}
		&__item, &__item_4 {
			margin-bottom: 30px;
		}
	}
	.industries__title {
		padding-left: 20px;
		padding-right: 20px;
		font-size: 18px
	}
	.industries__item_2 .industries__title {
	    padding-top: 42px;
	    padding-bottom: 43px;
	}
	.anim-17 {
		left: -23px;
	}
	.anim-18 {
		left: -17px;
	}
	.anim-19 {
	    right: -22px;
	    top: -64px;
	}
	.where__heading {
		font-size: 27px
	}
	.where__heading strong {
		font-size: 36px
	}
	.where__heading span {
		font-size: 18px
	}
	.where__logo {
		max-width: 33.33%
	}
}

@media screen and (min-width: 768px) and (max-width:1180px ) {
	.speakers__item {
		display: block;
	}
	.speakers__details {
		margin: 60px auto 0;
		max-width: 400px;
		width: 100%
	}
	.speakers__picture {
		margin: 0 auto;
	}
}
@media screen and (min-width: 768px) and (max-width:991px ) {


	.anim-1 {
	    width: 252px;
	    height: 182px;
	    left: -33px;
	    top: -43px;
	}
	.anim-5 {
	    width: 261px;
	    height: 178px;
	}
	.anim-3  {
		right: 10px;
	}
	.content-seminar .where__img img {
	    width: 389px;
	}
	.content-seminar .where__picture {
		max-width: 389px
	}
	.where__heading {
		font-size: 25px
	}
	.where__heading strong {
		font-size: 33px
	}
	.where__heading span {
		font-size: 16px
	}
}
@media screen and  (max-width: 767px) {
	.container {
		padding-left: 15px;
		padding-right: 15px
	}
	h1, .h1 {
		font-size: 24px
	}
	h2, .h2 {
		font-size: 22px;
		padding-bottom: 25px
	}
	.video-bg video {
	    display: none;
	}	
	.main-block__subtitle, .main-block__date {
		font-size: 18px;
	}
	.section-main {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 140px 0 75px;
		.container {
			display: block;
			height: auto;
		}
	} 
	.main-block__links {
	    margin-top: 58px;
	}
	.btn {
	    height:49px;
	    line-height: 45px;
	    font-size: 15px;
	}
	.block-links_item {
		margin-bottom: 15px
	}
	.special {
		display: block;
		&__picture, &__details {
			width: 100%;
		}
		&__picture {
			text-align: center;
		}
		&__details {
			margin-top: 30px;
		}
		&__slogan {
			font-size: 17px
		}
	}
	.content-seminar, .speakers {
		display: block;
		.where__picture {
			width: 100%
		}
	}
	.content-seminar__list {
		padding-right: 0;
		width: 100%;
	}
	.speakers__item {
		width: 100%
	}
	.auditory__title {
		font-size: 17px
	}
	.auditory__description {
		font-size: 14px;
		line-height: 1.25
	}
	.speakers__item {
		align-items: center;
		margin-bottom: 40px;
	}
	.speakers__name {
		font-size: 19px
	}
	.speakers__description {
		font-size: 14px;
	}
	.review-wrap, .where {
		display: block;
	}
	.review__description, .where__item {
		width: 100%;
	}
	.review__picture {
		margin: 0 auto;
		text-align: center;
		width: 100%;
			max-width: 460px;
	}
	.review__author {
		font-size: 17px
	}
	.review__ratingvalue {
		font-size: 20px
	}
	.section-where h2 {
		padding-bottom: 30px
	}
	.where {
		margin-top: 0;
	}
	.where__heading {
		padding: 20px 15px
	}
	.anim-17 {
		top: -27px;
	}
	.anim-19 {
	    top: -14px;
	}
	.where__item {
		margin: 0 auto 70px;
	}
	.where__title {
		font-size: 17px
	}
	.section-special {
	    margin-top: 61px;
	    padding-top: 69px;
	}
	.section-auditory {
	    margin-top: 30px;
	    padding-top: 55px;
	}
	.section-content-seminar {
	    margin-top: 69px;
	    padding-top: 49px;
	}
	.auditory {
		margin-top: 0
	}
	.review__img {
		margin: 0;
	}
	.review__img img {
		display: inline-block;
	}
	.clients__item {
		margin-bottom: 30px;
		width: 33.333%
	}
	.arrows-slider {
		margin-top: 30px;
	}
	.content-seminar__title {
		font-size: 14px;
	}
	.where__picture {
		margin: 40px auto 0;
	}
	.reviews {
		margin-top: 30px;
		margin-right: 0
	}
	.reviews-slider {
	    margin-right: 0;
	}
	.review__description {
		padding-right: 0
	}
	.order {
		padding: 29px 20px 35px;
	}
	.order__heading {
		font-size: 20px
	}
	.order-list {
		padding: 0
	}
	.input__field, .input__label {
		font-size: 14px
	}
	.practice-list__item {
		font-size: 15px;
		line-height: 20px;
	}
	.practice-list__item:before {
		top: 1px;
	}
	.where__picture_wrap {
		margin-top: 65px;
		padding: 0;
		width: 100%
	}
	.content-seminar .where__picture {
		margin: 0 auto;
	}
	.section-experience {
	    padding-top: 40px;
	}
	.reviews {
		padding: 22px 20px
	}
	.review__text {
		padding-bottom: 15px
	}
	.section-order {
	    margin-top: 48px;
	}
	.section-order .h2 {
		padding-bottom: 25px
	}
	.btn {
		min-width: 180px
	}
	.section-clients {
	    margin-top: 56px;
	}
	.clients {
		margin-top: 0
	}
	.content {
	    padding-bottom: 25px;
	}
	.section-speakers {
	    margin-top: 89px;
	    padding-top: 47px;
	    padding-bottom: 30px
	}
	.anim-1 {
	    width: 80%;
	    height: 60%;
	    left: -15%;
	    top: -43px;
	}
	.anim-2 {
	    width: 45%;
	    height: 45%;
	    left: -10%;
	}
	.anim-5 {
	    width: 80%;
	    height: 59%;
	    left: -8%;
	    bottom: -12%;
	}
	.anim-16 {
	    width: 45%;
	    height: 50%;
	    right: -3%;
	    bottom: -30px;
	}
	.anim-14 {
	    width: 30%;
	    height: 122px;
	    left: -5%;
	    bottom: 3%;
	}
	.section-where {
	    padding-top: 56px;
	}
	.content-seminar__list:before {
		top: 30px;
	}
	.reviews-slider .slick-list {
	    overflow: hidden;
	}
	.reviews-slider {
		margin-left: -15px;
		margin-right: -15px;
		&__item {
			padding: 0 15px
		}
	}
	.where__heading {
		font-size: 17px
	}
	.where__heading strong {
		font-size: 23px
	}
	.where__heading span {
		font-size: 14px
	}
	.auditory__details {
		padding: 20px 20px 25px;
		min-height: 0;
	}
	.auditory__item-2 {
		min-height: 0
	}
	.auditory__item-2 img {
		height: auto;
	}
	.content-seminar {
		margin-top: 10px
	}
	.section-industries {
	    padding: 40px 0
	}
	.industries {
		flex-wrap: wrap;
	}
	.industries__column {
		margin-bottom: 30px;
		width: 50%;
		&:nth-child(3) {
			flex-direction: row;
			width: 100%;
			.industries__item {
				margin: 0 10px 0 0;
				width: calc(50% - 10px);
				&:nth-child(2) {
					margin: 0 0 0 10px;
				}
			}
		}
	}
	.scroll-down {
		display: none;
	}
}
@media screen and (max-width: 560px) {
	.auditory__item {
		width: 100%;
		&:first-child {
			order: 1;
		}
		&:nth-child(2) {
			order: 2;
		}
		&:nth-child(3) {
			order: 3;
		}
	}
	.speakers__item {
		display: block;
	}
	.speakers__picture {
		text-align: center;
		max-width: 172px;
		margin: 0 auto;
		width: 100%
	}
	.speakers__details {
		margin-top: 15px;
		width: 100%
	}
	.speakers__name, .speakers__position {
		text-align: center;
	}
	.speakers .socials {
		text-align: center;
	}
	.clients__item {
		width: 50%
	}
	.speakers__picture_wrap {
	    margin-left: 0;
	}
	.speakers__item + .speakers__item {
		margin-top: 20px
	}
	.speakers__details {
		padding-left: 0
	}
	.where__heading {
		display: block;
	}
	.where__details {
		text-align: center;
		padding-right: 0
	}
	.where__logo {
		margin: 20px auto 8px;
		max-width: 100%;
		padding-left: 0
	}
}
@media screen and (max-width: 480px) {
	.industries__column {
		margin-left: auto;
		margin-right: auto;
		max-width: 290px;
		width: 100%
	}
	.industries__column:nth-child(3) {
		display: block;
	}
	.industries__column:nth-child(3) .industries__item {
		margin: 0 0 30px;
		width: 100%
	}
	.industries__column:nth-child(3) .industries__item:nth-child(2) {
		margin-left: 0
	}
	.hide-desc {
		display: inline-block;
	}
	.hide-mob {
		display: none;
	}
	.industries__title, .industries__item_2 .industries__title, .industries__item_3 .industries__title,
	.industries__item_4 .industries__title, .industries__item_5 .industries__title {
		padding-top: 20px;
		padding-bottom: 25px
	}
}