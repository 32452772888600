
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900&subset=cyrillic-ext');

@font-face {
    font-family:'Roboto';
    src: url('../fonts/Roboto.eot');
	src: url('../fonts/Roboto.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Roboto.woff2') format('woff2'),
		url('../fonts/Roboto.woff') format('woff'),
		url('../fonts/Roboto.ttf') format('truetype'),
		url('../fonts/Roboto.svg#Roboto') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}
@font-face {
    font-family:'Roboto';
    src: url('../fonts/Roboto Light.eot');
	src: url('../fonts/Roboto Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Roboto Light.woff2') format('woff2'),
		url('../fonts/Roboto Light.woff') format('woff'),
		url('../fonts/Roboto Light.ttf') format('truetype'),
		url('../fonts/Roboto Light.svg#Roboto Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}


@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?zftool');
  src:  url('../fonts/icomoon.eot?zftool#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?zftool') format('truetype'),
    url('../fonts/icomoon.woff?zftool') format('woff'),
    url('../fonts/icomoon.svg?zftool#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-envelope:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e902";
}
.icon-arrow-right:before {
  content: "\e909";
}
.icon-check:before {
  content: "\e90a";
}
.icon-facebook:before {
  content: "\e90b";
}
.icon-facebook_1:before {
  content: "\e90c";
}
.icon-linkedin:before {
  content: "\e90d";
}
.icon-mail:before {
  content: "\e90e";
  color: #2693ee;
}
.icon-phone:before {
  content: "\e90f";
}
.icon-skype:before {
  content: "\e910";
}
.icon-star:before {
  content: "\e911";
}
.icon-youtube:before {
  content: "\f167";
}
