
/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/
footer{
	background: #F0F5FB;
	border-top: 2px solid rgba(196, 196, 196, 0.4);	
	color: #828282;
	width:100%;
	font-weight: 600;
	font-family: $custom-font-family;
	font-size: 16px;
	min-width: $min-width;
	order: 3;	
    padding: 50px 0 40px;
    .logo {
    	width: 166px;
    }
	a {
		color: inherit;
		&:hover {
			color: $color-active;
		}
	}
}
.footer {
	margin: 0 -15px;
	display: flex;
	&__heading {
		color: $title-color;
		font-weight: bold;
		font-size: 20px;
		margin-bottom: 40px;
	}
	&-menu, &__contacts {
		padding: 0 15px;
		width: 50%
	}
}
.footer-nav {
	&__item {
		display: inline-block;
		margin: 0 -4px 41px 0;
		vertical-align: top;
		width: 50%;	
	}
}
.contacts-item {
	display: inline-block;
	margin: 0 -4px 37px 0;
	vertical-align: top;
	width: 50%;	
	i {
		color: $color-active;
		font-size: 24px;
		margin-right: 15px;
		vertical-align: middle;
	}
}
.footer-bottom {
	border-top: 2px solid rgba(196, 196, 196, 0.4);
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	padding-top: 40px
}

.copyrights {
	font-size: 14px;
	font-weight: 600;
	a {
		color: $color-active;
		display: inline-block;
		&:after {
			content: '';
			background: $color-active;
			display: block;
			height: 1px;
			width: 100%;
			transform: scale(1);
			transition: transform .3s ease-in;
		}
		&:hover:after{
			transform: scale(0);
		}
	}
}
@media screen and (max-width: 1200px) {
	
}

@media screen and (min-width: 992px){
	
}

@media screen and (max-width: 991px) {
	
	.footer-nav__item, .contacts-item {
		width: 100%
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	
}

@media screen and (max-width: 767px) {
	footer {
		font-size: 15px;
		padding: 40px 0 30px;
		text-align: center;
	}
	.footer, .footer-bottom {
		display: block;
	}
	.footer-menu, .footer__contacts {
		margin: 0 auto;
		max-width: 280px;
		width: 100%
	}
	.footer-nav__item, .contacts-item {
		margin-bottom: 25px
	}
	.footer-bottom {
		text-align: center;
	}
	footer .logo {
		display: inline-block;
	}
	.copyrights {
		margin-top: 20px
	}
	.footer__contacts {
		margin-top: 15px;
	}

}