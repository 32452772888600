/* Slider */

.slick-slider {
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-arrow {
  background: none;
  display: block;
  padding: 0;
  height: 48px;
  width: 48px;
  border: 2px solid $color-active;
  border-width: 0 0 2px 2px
}
.slick-prev, .prev {
  transform: rotate(45deg);
}
.slick-next, .next  {
  display: block;
  transform: rotate(225deg);
}
.prev, .next  {
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  .slick-arrow {
    height: 13px;
    width: 13px; 
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -8px 0 0 -5px;
    transition: box-shadow .3s ease-in
  }
}
.prev:hover, .next:hover {box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);}
.prev .slick-arrow {
    margin: -8px 0 0 -5px

}
@media screen and  (max-width: 767px) {
  .slick-arrow {
      height: 16px;
      margin-top: -8px;
      width: 16px;
  }
}