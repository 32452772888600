/* reset */
$min-width: 320px; 
$max-width: 1226px; 
$base-bg: #FFF; 
$base-font-size: 14px; 
$base-line-height: 1.2; 
$base-font-family: Roboto, Arial, sans-serif; 
$custom-font-family: Montserrat, Arial, sans-serif; 

$color-active: #2693EE;
$link-color: $color-active;
$default-transition:all 0.2s linear;

/* main variables */

//text, titles
$text-color: #2E343E;
$title-color: $text-color;
$title-font-family: $custom-font-family;

$font-size-h1: 36px;
$line-height-h1: 42px;
$font-size-h2: 32px;
$line-height-h2: 37px;
$font-size-h3: 28px;
$line-height-h3: 32px;