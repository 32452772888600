
/*
================================================================================
|                                     HEADER                                   |
================================================================================
*/
header{
	background:#fff;
	font-family: $custom-font-family;
	width:100%;
	min-width: $min-width;
	padding: 29px 0 27px;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	text-align:left;	
	order: 1;	
    -webkit-order: 1;
    z-index: 100;
    transition: padding .3s ease-in;
    &.fix {
    	background: rgba(255,255,255,.95);
    	box-shadow: 0 0 3px rgba(0,0,0,.1);
    	padding: 15px 0 13px
    }
}
.header-container {
	padding-top: 15px;
	padding-bottom: 15px;
}
.logo {
	display: block;
	width: 128px
}
.main-nav{
	list-style: none;
	padding-bottom: 4px;
	&__item{
		display: inline-block;
		margin-left: 47px;
	}
	&__link{
		color: #2E343E;
		font-size: 16px;
		font-weight: 600;
		&.mPS2id-highlight, &:hover{
			color:$color-active;
		}
	
	}
}
.header-container{
	align-items: center;
	display: flex;
	justify-content: space-between;
}
.button-nav {
	display: none;
}
@media screen and (max-width: 1200px) {
	
}

@media screen and (min-width: 992px){
	
}

@media screen and (max-width: 991px) {
	
	.menu {
		display: none;
		position: fixed;
		top: 55px;
		padding: 40px 20px;
		min-width: $min-width;
		left: 0;
		right: 0;
		bottom: 0;
		background: #fff;
		z-index: 100
	}
	.main-nav__item {
		display: block;
		margin: 0 0 20px;
		text-align: center;
	}
	.button-nav {
	    display: block;
	    height: 30px;
	    margin: -3px 1px 0 0;
	    position: relative;
	    width: 18px;
	    z-index: 11;
	}
	.button-nav__cont {
		top: 50%;
		transform: translate(0, -50%);
		transition: background 0s ease-in 0.3s;
	}
	.button-nav__cont:before, .button-nav__cont:after, .button-nav__cont {
	    width: 100%;
	    height: 2px;
	    opacity: 0.75;
	    background: #000;
	    position: absolute;
	    left: 0;
	}
	.button-nav__cont:before, .button-nav__cont:after {
	    content: '';
	    display: block;
	    transition: transform .3s ease-in;
	}
	.button-nav__cont:before {
	    top: -5px;
	}
	.button-nav__cont:after {
	    bottom: -5px;
	}
	.button-nav.open .button-nav__cont {
	    background: transparent;
	    transition: background 0s ease-in 0s;
	}
	.button-nav.open .button-nav__cont:before {
	    transform: rotate(45deg) translate(0, 7px);
	}
	.button-nav.open .button-nav__cont:after {
	    transform: rotate(-45deg) translate(0, -7px);
	}	
}



@media screen and (max-width: 767px) {
	header {
		padding: 15px 0;
		header.fix {
			padding:  10px 0 5px
		}
	}
}
