/* reset */
/* main variables */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900&subset=cyrillic-ext");
body { color: #2E343E; margin: 0px; padding: 0px; font-family: Roboto, Arial, sans-serif; background-color: #FFF; height: 100%; line-height: 1.2; position: relative; font-size: 14px !important; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

/*MAIN*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main { display: block; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }

table { border-collapse: collapse; border-width: 0px; padding: 0px; margin: 0px; }

html { height: 100%; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; }

input, textarea { color: #333; font-family: Roboto, Arial, sans-serif; outline: none; border-radius: 0; -moz-border-radius: 0; -webkit-border-radius: 0; -webkit-appearance: none; }

input[type="button"], input[type="submit"], button { cursor: pointer; }

td { margin: 0px; padding: 0px; }

form { padding: 0px; margin: 0px; }

a { color: #2693EE; -webkit-transition: all 0.2s linear; -moz-transition: all 0.2s linear; -ms-transition: all 0.2s linear; -o-transition: all 0.2s linear; transition: all 0.2s linear; text-decoration: none; outline: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

a, span, div, button { outline: none !important; }

input[type=submit], input[type=button], button { -webkit-appearance: none; outline: none; }

* { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

*:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.clearfix:after, .wrapper:after, .row:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }

img { max-width: 100%; }

@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto.eot"); src: url("../fonts/Roboto.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto.woff2") format("woff2"), url("../fonts/Roboto.woff") format("woff"), url("../fonts/Roboto.ttf") format("truetype"), url("../fonts/Roboto.svg#Roboto") format("svg"); font-weight: 400; font-style: normal; font-stretch: normal; unicode-range: U+0020-2044; }

@font-face { font-family: 'Roboto'; src: url("../fonts/Roboto Light.eot"); src: url("../fonts/Roboto Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto Light.woff2") format("woff2"), url("../fonts/Roboto Light.woff") format("woff"), url("../fonts/Roboto Light.ttf") format("truetype"), url("../fonts/Roboto Light.svg#Roboto Light") format("svg"); font-weight: 300; font-style: normal; font-stretch: normal; unicode-range: U+0020-2044; }

@font-face { font-family: 'icomoon'; src: url("../fonts/icomoon.eot?zftool"); src: url("../fonts/icomoon.eot?zftool#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?zftool") format("truetype"), url("../fonts/icomoon.woff?zftool") format("woff"), url("../fonts/icomoon.svg?zftool#icomoon") format("svg"); font-weight: normal; font-style: normal; }

[class^="icon-"], [class*=" icon-"] { /* use !important to prevent issues with browser extensions that change fonts */ font-family: 'icomoon' !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.icon-envelope:before { content: "\e900"; }

.icon-arrow-left:before { content: "\e902"; }

.icon-arrow-right:before { content: "\e909"; }

.icon-check:before, .practice-list__item:before { content: "\e90a"; }

.icon-facebook:before { content: "\e90b"; }

.icon-facebook_1:before { content: "\e90c"; }

.icon-linkedin:before { content: "\e90d"; }

.icon-mail:before { content: "\e90e"; color: #2693ee; }

.icon-phone:before { content: "\e90f"; }

.icon-skype:before { content: "\e910"; }

.icon-star:before { content: "\e911"; }

.icon-youtube:before { content: "\f167"; }

body.fancybox-active { overflow: hidden; }

body.fancybox-iosfix { position: fixed; left: 0; right: 0; margin-right: 0; }

.fancybox-is-hidden { position: absolute; top: -9999px; left: -9999px; visibility: hidden; }

.fancybox-container { position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 99992; -webkit-tap-highlight-color: transparent; -webkit-backface-visibility: hidden; backface-visibility: hidden; -webkit-transform: translateZ(0); transform: translateZ(0); }

.fancybox-outer, .fancybox-inner, .fancybox-bg, .fancybox-stage { position: absolute; top: 0; right: 0; bottom: 0; left: 0; }

.fancybox-outer { overflow-y: auto; -webkit-overflow-scrolling: touch; }

.fancybox-bg { background: #1e1e1e; opacity: 0; transition-duration: inherit; transition-property: opacity; transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg { opacity: 0.87; transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar, .fancybox-toolbar, .fancybox-caption-wrap { position: absolute; direction: ltr; z-index: 99997; opacity: 0; visibility: hidden; transition: opacity .25s, visibility 0s linear .25s; box-sizing: border-box; }

.fancybox-show-infobar .fancybox-infobar, .fancybox-show-toolbar .fancybox-toolbar, .fancybox-show-caption .fancybox-caption-wrap { opacity: 1; visibility: visible; transition: opacity .25s, visibility 0s; }

.fancybox-infobar { top: 0; left: 0; font-size: 13px; padding: 0 10px; height: 44px; min-width: 44px; line-height: 44px; color: #ccc; text-align: center; pointer-events: none; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -webkit-touch-callout: none; -webkit-tap-highlight-color: transparent; -webkit-font-smoothing: subpixel-antialiased; mix-blend-mode: exclusion; }

.fancybox-toolbar { top: 0; right: 0; margin: 0; padding: 0; }

.fancybox-stage { overflow: hidden; direction: ltr; z-index: 99994; -webkit-transform: translate3d(0, 0, 0); overflow: auto; }

.fancybox-is-closing .fancybox-stage { overflow: visible; }

.fancybox-slide { position: absolute; top: 0; left: 0; width: 100%; height: 100%; margin: 0; padding: 0; overflow: auto; outline: none; white-space: normal; box-sizing: border-box; text-align: center; z-index: 99994; -webkit-overflow-scrolling: touch; display: none; -webkit-backface-visibility: hidden; backface-visibility: hidden; transition-property: opacity, -webkit-transform; transition-property: transform, opacity; transition-property: transform, opacity, -webkit-transform; }

.fancybox-slide::before { content: ''; display: inline-block; vertical-align: middle; height: 100%; width: 0; }

.fancybox-is-sliding .fancybox-slide, .fancybox-slide--previous, .fancybox-slide--current, .fancybox-slide--next { display: block; }

.fancybox-slide--image { overflow: visible; }

.fancybox-slide--image::before { display: none; }

.fancybox-slide--video .fancybox-content, .fancybox-slide--video iframe { background: #000; }

.fancybox-slide--map .fancybox-content, .fancybox-slide--map iframe { background: #E5E3DF; }

.fancybox-slide--next { z-index: 99995; }

.fancybox-slide > * { display: inline-block; position: relative; margin: 0; border-width: 0; vertical-align: middle; text-align: left; background-color: #fff; box-sizing: border-box; }

.fancybox-slide > title, .fancybox-slide > style, .fancybox-slide > meta, .fancybox-slide > link, .fancybox-slide > script, .fancybox-slide > base { display: none; }

.fancybox-slide .fancybox-image-wrap { position: absolute; top: 0; left: 0; margin: 0; padding: 0; border: 0; z-index: 99995; background: transparent; cursor: default; overflow: visible; -webkit-transform-origin: top left; -ms-transform-origin: top left; transform-origin: top left; background-size: 100% 100%; background-repeat: no-repeat; -webkit-backface-visibility: hidden; backface-visibility: hidden; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; transition-property: opacity, -webkit-transform; transition-property: transform, opacity; transition-property: transform, opacity, -webkit-transform; display: flex; align-items: center; justify-content: center; }

.fancybox-can-zoomOut .fancybox-image-wrap { cursor: -webkit-zoom-out; cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-image-wrap { cursor: -webkit-zoom-in; cursor: zoom-in; }

.fancybox-can-drag .fancybox-image-wrap { cursor: -webkit-grab; cursor: grab; }

.fancybox-is-dragging .fancybox-image-wrap { cursor: -webkit-grabbing; cursor: grabbing; }

.fancybox-image, .fancybox-spaceball { margin: 0; padding: 0; border: 0; max-width: 100%; max-height: 100%; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.fancybox-spaceball { z-index: 1; }

.fancybox-slide--iframe .fancybox-content { padding: 0; width: 80%; height: 80%; max-width: calc(100% - 100px); max-height: calc(100% - 88px); overflow: visible; background: #fff; }

.fancybox-iframe { display: block; margin: 0; padding: 0; border: 0; width: 100%; height: 100%; background: #fff; }

.fancybox-error { margin: 0; padding: 40px; width: 100%; max-width: 380px; background: #fff; cursor: default; }

.fancybox-error p { margin: 0; padding: 0; color: #444; font-size: 16px; line-height: 20px; }

/* Buttons */
.fancybox-button { box-sizing: border-box; display: inline-block; vertical-align: top; width: 44px; height: 44px; margin: 0; padding: 10px; border: 0; border-radius: 0; background: rgba(30, 30, 30, 0.6); transition: color .3s ease; cursor: pointer; outline: none; }

.fancybox-button, .fancybox-button:visited, .fancybox-button:link { color: #ccc; }

.fancybox-button:focus, .fancybox-button:hover { color: #fff; }

.fancybox-button[disabled] { color: #ccc; cursor: default; opacity: 0.6; }

.fancybox-button svg { display: block; position: relative; overflow: visible; shape-rendering: geometricPrecision; }

.fancybox-button svg path { fill: currentColor; stroke: currentColor; stroke-linejoin: round; stroke-width: 3; }

.fancybox-button--share svg path { stroke-width: 1; }

.fancybox-button--play svg path:nth-child(2) { display: none; }

.fancybox-button--pause svg path:nth-child(1) { display: none; }

.fancybox-button--zoom svg path { fill: transparent; }

/* Navigation arrows */
.fancybox-navigation { display: none; }

.fancybox-show-nav .fancybox-navigation { display: block; }

.fancybox-navigation button { position: absolute; top: 50%; margin: -50px 0 0 0; z-index: 99997; background: transparent; width: 60px; height: 100px; padding: 17px; }

.fancybox-navigation button:before { content: ""; position: absolute; top: 30px; right: 10px; width: 40px; height: 40px; background: rgba(30, 30, 30, 0.6); }

.fancybox-navigation .fancybox-button--arrow_left { left: 0; }

.fancybox-navigation .fancybox-button--arrow_right { right: 0; }

/* Close button on the top right corner of html content */
.fancybox-close-small { color: #2E343E; font-size: 17px; position: absolute; top: 6px; right: 3px; width: 36px; height: 36px; padding: 0; margin: 0; border: 0; border-radius: 0; background: transparent; z-index: 10; cursor: pointer; transition: all 0.2s linear; }

.fancybox-close-small:hover { transform: rotate(360deg); }

.fancybox-close-small:focus { outline: none; }

.fancybox-close-small:hover:after { color: #555; background: #eee; }

.fancybox-slide--image .fancybox-close-small, .fancybox-slide--iframe .fancybox-close-small { top: 0; right: -40px; }

.fancybox-slide--image .fancybox-close-small:after, .fancybox-slide--iframe .fancybox-close-small:after { font-size: 35px; color: #aaa; }

.fancybox-slide--image .fancybox-close-small:hover:after, .fancybox-slide--iframe .fancybox-close-small:hover:after { color: #fff; background: transparent; }

.fancybox-is-scaling .fancybox-close-small, .fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small { display: none; }

/* Caption */
.fancybox-caption-wrap { bottom: 0; left: 0; right: 0; padding: 60px 2vw 0 2vw; background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.8) 100%); pointer-events: none; }

.fancybox-caption { padding: 30px 0; border-top: 1px solid rgba(255, 255, 255, 0.4); font-size: 14px; color: #fff; line-height: 20px; -webkit-text-size-adjust: none; }

.fancybox-caption a, .fancybox-caption button, .fancybox-caption select { pointer-events: all; position: relative; /* Fix IE11 */ }

.fancybox-caption a { color: #fff; text-decoration: underline; }

/* Loading indicator */
.fancybox-slide > .fancybox-loading { border: 6px solid rgba(100, 100, 100, 0.4); border-top: 6px solid rgba(255, 255, 255, 0.6); border-radius: 100%; height: 50px; width: 50px; -webkit-animation: fancybox-rotate .8s infinite linear; animation: fancybox-rotate .8s infinite linear; background: transparent; position: absolute; top: 50%; left: 50%; margin-top: -30px; margin-left: -30px; z-index: 99999; }

@-webkit-keyframes fancybox-rotate { from { -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to { -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fancybox-rotate { from { -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to { -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/* Transition effects */
.fancybox-animated { transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous { -webkit-transform: translate3d(-100%, 0, 0); transform: translate3d(-100%, 0, 0); opacity: 0; }

.fancybox-fx-slide.fancybox-slide--next { -webkit-transform: translate3d(100%, 0, 0); transform: translate3d(100%, 0, 0); opacity: 0; }

.fancybox-fx-slide.fancybox-slide--current { -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); opacity: 1; }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous, .fancybox-fx-fade.fancybox-slide--next { opacity: 0; transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current { opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous { -webkit-transform: scale3d(1.5, 1.5, 1.5); transform: scale3d(1.5, 1.5, 1.5); opacity: 0; }

.fancybox-fx-zoom-in-out.fancybox-slide--next { -webkit-transform: scale3d(0.5, 0.5, 0.5); transform: scale3d(0.5, 0.5, 0.5); opacity: 0; }

.fancybox-fx-zoom-in-out.fancybox-slide--current { -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); opacity: 1; }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous { -webkit-transform: rotate(-360deg); -ms-transform: rotate(-360deg); transform: rotate(-360deg); opacity: 0; }

.fancybox-fx-rotate.fancybox-slide--next { -webkit-transform: rotate(360deg); -ms-transform: rotate(360deg); transform: rotate(360deg); opacity: 0; }

.fancybox-fx-rotate.fancybox-slide--current { -webkit-transform: rotate(0deg); -ms-transform: rotate(0deg); transform: rotate(0deg); opacity: 1; }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous { -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); opacity: 0; }

.fancybox-fx-circular.fancybox-slide--next { -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); opacity: 0; }

.fancybox-fx-circular.fancybox-slide--current { -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0); transform: scale3d(1, 1, 1) translate3d(0, 0, 0); opacity: 1; }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous { -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next { -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }

/* Share */
.fancybox-share { padding: 30px; border-radius: 3px; background: #f4f4f4; max-width: 90%; text-align: center; }

.fancybox-share h1 { color: #222; margin: 0 0 20px 0; font-size: 35px; font-weight: 700; }

.fancybox-share p { margin: 0; padding: 0; }

p.fancybox-share__links { margin-right: -10px; }

.fancybox-share__button { display: inline-block; text-decoration: none; margin: 0 10px 10px 0; padding: 0 15px; min-width: 130px; border: 0; border-radius: 3px; background: #fff; white-space: nowrap; font-size: 14px; font-weight: 700; line-height: 40px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; color: #fff; transition: all .2s; }

.fancybox-share__button:hover { text-decoration: none; }

.fancybox-share__button--fb { background: #3b5998; }

.fancybox-share__button--fb:hover { background: #344e86; }

.fancybox-share__button--pt { background: #bd081d; }

.fancybox-share__button--pt:hover { background: #aa0719; }

.fancybox-share__button--tw { background: #1da1f2; }

.fancybox-share__button--tw:hover { background: #0d95e8; }

.fancybox-share__button svg { position: relative; top: -1px; width: 25px; height: 25px; margin-right: 7px; vertical-align: middle; }

.fancybox-share__button svg path { fill: #fff; }

.fancybox-share__input { box-sizing: border-box; width: 100%; margin: 10px 0 0 0; padding: 10px 15px; background: transparent; color: #5d5b5b; font-size: 14px; outline: none; border: 0; border-bottom: 2px solid #d7d7d7; }

/* Thumbs */
.fancybox-thumbs { display: none; position: absolute; top: 0; bottom: 0; right: 0; width: 212px; margin: 0; padding: 2px 2px 4px 2px; background: #fff; -webkit-tap-highlight-color: transparent; -webkit-overflow-scrolling: touch; -ms-overflow-style: -ms-autohiding-scrollbar; box-sizing: border-box; z-index: 99995; }

.fancybox-thumbs-x { overflow-y: hidden; overflow-x: auto; }

.fancybox-show-thumbs .fancybox-thumbs { display: block; }

.fancybox-show-thumbs .fancybox-inner { right: 212px; }

.fancybox-thumbs > ul { list-style: none; position: absolute; position: relative; width: 100%; height: 100%; margin: 0; padding: 0; overflow-x: hidden; overflow-y: auto; font-size: 0; white-space: nowrap; }

.fancybox-thumbs-x > ul { overflow: hidden; }

.fancybox-thumbs-y > ul::-webkit-scrollbar { width: 7px; }

.fancybox-thumbs-y > ul::-webkit-scrollbar-track { background: #fff; border-radius: 10px; box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y > ul::-webkit-scrollbar-thumb { background: #2a2a2a; border-radius: 10px; }

.fancybox-thumbs > ul > li { float: left; overflow: hidden; padding: 0; margin: 2px; width: 100px; height: 75px; max-width: calc(50% - 4px); max-height: calc(100% - 8px); position: relative; cursor: pointer; outline: none; -webkit-tap-highlight-color: transparent; -webkit-backface-visibility: hidden; backface-visibility: hidden; box-sizing: border-box; }

li.fancybox-thumbs-loading { background: rgba(0, 0, 0, 0.1); }

.fancybox-thumbs > ul > li > img { position: absolute; top: 0; left: 0; max-width: none; max-height: none; -webkit-touch-callout: none; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.fancybox-thumbs > ul > li:before { content: ''; position: absolute; top: 0; right: 0; bottom: 0; left: 0; border: 4px solid #4ea7f9; z-index: 99991; opacity: 0; transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

.fancybox-thumbs > ul > li.fancybox-thumbs-active:before { opacity: 1; }

@media screen and (max-width: 992px) { .ios .fancybox-toolbar .fancybox-button { display: block !important; } }

@media screen and (max-width: 767px) { .fancybox-slide--iframe .fancybox-content { width: 100%; height: 100%; max-width: calc(100% - 10px); max-height: calc(100% - 10px); } }

/* Slider */
.slick-slider { position: relative; display: block; -moz-box-sizing: border-box; box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.slick-arrow.slick-hidden { display: none; }

.slick-arrow { background: none; display: block; padding: 0; height: 48px; width: 48px; border: 2px solid #2693EE; border-width: 0 0 2px 2px; }

.slick-prev, .prev { transform: rotate(45deg); }

.slick-next, .next { display: block; transform: rotate(225deg); }

.prev, .next { background: #FFFFFF; box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1); border: 0; border-radius: 50%; display: inline-block; margin-right: 10px; width: 40px; height: 40px; vertical-align: middle; }

.prev .slick-arrow, .next .slick-arrow { height: 13px; width: 13px; position: absolute; left: 50%; top: 50%; margin: -8px 0 0 -5px; transition: box-shadow .3s ease-in; }

.prev:hover, .next:hover { box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15); }

.prev .slick-arrow { margin: -8px 0 0 -5px; }

@media screen and (max-width: 767px) { .slick-arrow { height: 16px; margin-top: -8px; width: 16px; } }

/*
================================================================================
|                                     HEADER                                   |
================================================================================
*/
header { background: #fff; font-family: Montserrat, Arial, sans-serif; width: 100%; min-width: 320px; padding: 29px 0 27px; position: fixed; left: 0; right: 0; top: 0; text-align: left; order: 1; -webkit-order: 1; z-index: 100; transition: padding .3s ease-in; }

header.fix { background: rgba(255, 255, 255, 0.95); box-shadow: 0 0 3px rgba(0, 0, 0, 0.1); padding: 15px 0 13px; }

.header-container { padding-top: 15px; padding-bottom: 15px; }

.logo { display: block; width: 128px; }

.main-nav { list-style: none; padding-bottom: 4px; }

.main-nav__item { display: inline-block; margin-left: 47px; }

.main-nav__link { color: #2E343E; font-size: 16px; font-weight: 600; }

.main-nav__link.mPS2id-highlight, .main-nav__link:hover { color: #2693EE; }

.header-container { align-items: center; display: flex; justify-content: space-between; }

.button-nav { display: none; }

@media screen and (max-width: 991px) { .menu { display: none; position: fixed; top: 55px; padding: 40px 20px; min-width: 320px; left: 0; right: 0; bottom: 0; background: #fff; z-index: 100; }
  .main-nav__item { display: block; margin: 0 0 20px; text-align: center; }
  .button-nav { display: block; height: 30px; margin: -3px 1px 0 0; position: relative; width: 18px; z-index: 11; }
  .button-nav__cont { top: 50%; transform: translate(0, -50%); transition: background 0s ease-in 0.3s; }
  .button-nav__cont:before, .button-nav__cont:after, .button-nav__cont { width: 100%; height: 2px; opacity: 0.75; background: #000; position: absolute; left: 0; }
  .button-nav__cont:before, .button-nav__cont:after { content: ''; display: block; transition: transform .3s ease-in; }
  .button-nav__cont:before { top: -5px; }
  .button-nav__cont:after { bottom: -5px; }
  .button-nav.open .button-nav__cont { background: transparent; transition: background 0s ease-in 0s; }
  .button-nav.open .button-nav__cont:before { transform: rotate(45deg) translate(0, 7px); }
  .button-nav.open .button-nav__cont:after { transform: rotate(-45deg) translate(0, -7px); } }

@media screen and (max-width: 767px) { header { padding: 15px 0; }
  header header.fix { padding: 10px 0 5px; } }

/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/
footer { background: #F0F5FB; border-top: 2px solid rgba(196, 196, 196, 0.4); color: #828282; width: 100%; font-weight: 600; font-family: Montserrat, Arial, sans-serif; font-size: 16px; min-width: 320px; order: 3; padding: 50px 0 40px; }

footer .logo { width: 166px; }

footer a { color: inherit; }

footer a:hover { color: #2693EE; }

.footer { margin: 0 -15px; display: flex; }

.footer__heading { color: #2E343E; font-weight: bold; font-size: 20px; margin-bottom: 40px; }

.footer-menu, .footer__contacts { padding: 0 15px; width: 50%; }

.footer-nav__item { display: inline-block; margin: 0 -4px 41px 0; vertical-align: top; width: 50%; }

.contacts-item { display: inline-block; margin: 0 -4px 37px 0; vertical-align: top; width: 50%; }

.contacts-item i { color: #2693EE; font-size: 24px; margin-right: 15px; vertical-align: middle; }

.footer-bottom { border-top: 2px solid rgba(196, 196, 196, 0.4); display: flex; justify-content: space-between; margin-top: 10px; padding-top: 40px; }

.copyrights { font-size: 14px; font-weight: 600; }

.copyrights a { color: #2693EE; display: inline-block; }

.copyrights a:after { content: ''; background: #2693EE; display: block; height: 1px; width: 100%; transform: scale(1); transition: transform .3s ease-in; }

.copyrights a:hover:after { transform: scale(0); }

@media screen and (max-width: 991px) { .footer-nav__item, .contacts-item { width: 100%; } }

@media screen and (max-width: 767px) { footer { font-size: 15px; padding: 40px 0 30px; text-align: center; }
  .footer, .footer-bottom { display: block; }
  .footer-menu, .footer__contacts { margin: 0 auto; max-width: 280px; width: 100%; }
  .footer-nav__item, .contacts-item { margin-bottom: 25px; }
  .footer-bottom { text-align: center; }
  footer .logo { display: inline-block; }
  .copyrights { margin-top: 20px; }
  .footer__contacts { margin-top: 15px; } }

.main-wrapper { padding: 0; min-width: 320px; width: 100%; position: relative; overflow: hidden; min-height: 100%; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; flex-direction: column; -webkit-flex-direction: column; -ms-flex-direction: column; justify-content: flex-start; -webkit-justify-content: flex-start; -ms-flex-pack: start; }

.container { min-width: 320px; max-width: 1226px; padding: 0 40px; margin: 0 auto; position: relative; }

/* titles */
p { padding: 15px 0 15px 0; font-weight: 400; }

h1, .h1 { font-weight: 900; font-family: Montserrat, Arial, sans-serif; font-size: 48px; padding: 15px 0 15px 0; text-align: center; }

h2, .h2 { font-family: Montserrat, Arial, sans-serif; color: #2E343E; font-weight: 900; font-size: 48px; padding: 15px 0 40px 0; text-align: center; }

h3 { font-family: Montserrat, Arial, sans-serif; font-size: 36px; padding: 15px 0 15px 0; font-weight: 900; text-align: center; }

/* text position */
.text-left { text-align: left !important; }

.text-center { text-align: center !important; }

.text-right { text-align: right !important; }

.nowrap { white-space: nowrap !important; }

/* loader */
.loaded .main-wrapper { visibility: hidden; }

.icon-load { background: url(../img/loader.gif) no-repeat left top; width: 40px; height: 40px; position: fixed; left: 50%; top: 50%; margin-left: -20px; margin-left: -20px; display: none; }

.loaded .icon-load { display: block; }

/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content { padding-bottom: 85px; min-width: 320px; text-align: left; width: 100%; order: 2; flex-grow: 1; }

.btn { border: 2px solid #2693EE; color: #fff; display: inline-block; font-family: Montserrat, Arial, sans-serif; height: 55px; line-height: 51px; background: #2693EE; border-radius: 129px; font-weight: 500; font-size: 16px; min-width: 264px; text-align: center; padding: 0 15px; box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2); transition: background .3s ease-in, color .3s ease-in; }

.btn:hover { background: transparent; color: #2693EE; }

.btn2 { background: transparent; color: #2693EE; }

.btn2:hover { background: #2693EE; color: #fff; }

.section-main { background-position: center center; background-repeat: no-repeat; background-size: cover; color: #fff; min-height: 100vh; font-family: Montserrat, Arial, sans-serif; display: table; padding: 170px 0 205px; position: relative; width: 100%; }

.section-main .container { display: table-cell; height: 100%; text-align: center; vertical-align: middle; z-index: 1; }

.section-main .h1 { color: inherit; padding: 0; }

.main-block { max-width: 1000px; margin: 0 auto; }

.main-block__subtitle { font-weight: 900; font-size: 25px; margin-bottom: 30px; }

.main-block__date { font-weight: 800; font-size: 25px; margin-top: 10px; }

.main-block__links { margin-top: 83px; }

.main-block__links .btn { box-shadow: none; }

.main-block__time { font-size: 16px; margin-top: 5px; }

.main-block .btn:hover { color: #fff; }

.main-block .btn2 { color: #fff; }

.block-links_item { display: inline-block; margin: 0 15px 30px; }

.video-bg { height: 100%; left: 0; position: absolute; right: 0; top: 0; z-index: 0; overflow: hidden; }

.video-bg video { height: 100%; position: relative; top: 50%; transform: translate(0, -50%); width: 100%; z-index: 0; }

.video-bg:before { content: ''; background: rgba(10, 19, 31, 0.78); bottom: 0; display: block; position: absolute; left: 0; right: 0; top: 0; z-index: 1; }

.scroll-down { background: url(../img/icons/mouse-scroll.svg) center center no-repeat; background-size: contain; display: block; height: 41px; width: 20px; position: absolute; left: 50%; transform: translate(-50%, 0); bottom: 15vh; animation: infinite; animation-name: mouse; animation-duration: 2s; animation-timing-function: ease-in-out; z-index: 1; }

@keyframes mouse { 0% { bottom: 50px; }
  50% { bottom: 30px; }
  100% { bottom: 50px; } }

.section-experience { padding-top: 108px; }

.reviews-thumb { margin-top: 30px; padding: 0 80px; position: relative; }

.reviews-thumb__item { text-align: center; }

.reviews-thumb__item img { cursor: pointer; }

.reviews-thumb__avatar { max-width: 53px; max-height: 53px; border-radius: 50%; }

.reviews-thumb__title { cursor: pointer; font-size: 16px; color: rgba(96, 96, 96, 0.4); opacity: 0.4; font-weight: 700; margin-top: 12px; }

.reviews-thumb .slick-arrow { position: absolute; top: 50%; margin-top: -24px; }

.reviews-thumb .slick-prev { left: 7px; }

.reviews-thumb .slick-next { right: 7px; }

.review-wrap { display: flex; }

.reviews-slider .slick-list { overflow: visible; }

.reviews { background: #FFF; box-shadow: 0 0 40px rgba(0, 0, 0, 0.2); padding: 68px 67px; margin-top: 116px; margin-right: 100px; }

.reviews-slider { margin-right: -100px; }

.review__description { padding-right: 30px; width: 61.66%; }

.review__picture { position: relative; width: 38.33%; }

.review__img { display: block; margin-top: -137px; margin-right: -72px; transform: translate(-3px, 0); }

.review__img img { display: block; max-width: 401px; }

.review__top { display: flex; justify-content: space-between; }

.review__rating { color: #2693EE; margin-left: -3px; }

.review__rating i { font-size: 23px; margin-right: 8px; }

.review__date { color: #2693EE; line-height: 21px; font-size: 14px; margin-right: 6px; }

.review__social { color: #2693EE; font-size: 20px; margin-left: 10px; }

.review__social i { font-size: 21px; }

.review__ratingvalue { font-family: Montserrat, Arial, sans-serif; font-weight: bold; font-size: 24px; }

.review__author { font-family: Montserrat, Arial, sans-serif; font-weight: 800; font-size: 24px; margin-top: 21px; }

.review__text { line-height: 25px; font-size: 14px; color: #606060; padding-bottom: 35px; }

.review__text p { padding-top: 11px; }

.reviews-thumb__item.slick-current .reviews-thumb__title { color: #2693EE; }

.arrows-slider { margin-top: -40px; }

.section-where { padding-top: 106px; }

.where { display: flex; margin: 98px 0 0; }

.where__item { width: 100%; max-width: 980px; margin: 0 auto; }

.where__title { font-family: Montserrat, Arial, sans-serif; font-weight: 800; font-size: 24px; text-align: center; margin-top: 70px; }

.where__img { position: relative; text-align: center; z-index: 1; }

.where__img img { vertical-align: top; }

.where__picture { margin: 0 auto; position: relative; }

.where_info { margin: 50px auto 0; font-size: 18px; font-weight: 300; line-height: 1.4; max-width: 800px; text-align: center; }

.where__details { padding-right: 20px; text-align: left; }

.where__logo { padding-left: 20px; }

.where__heading { background: linear-gradient(142.83deg, #429CEF 6.72%, #03C3FF 93.19%); color: #fff; display: flex; font-family: Montserrat, Arial, sans-serif; padding: 20px 20px 17px; font-size: 30px; font-weight: 700; line-height: 1.15; justify-content: space-between; align-items: center; }

.where__heading strong { font-weight: 900; font-size: 40px; }

.where__heading span { font-size: 18px; }

.section-auditory { margin-top: 26px; padding-top: 109px; }

.auditory { display: flex; margin-top: 30px; }

.auditory__item { color: #fff; position: relative; width: 33.333%; }

.auditory__item-1 { background: #2693EE; }

.auditory__item-2 { overflow: hidden; min-height: 320px; }

.auditory__item-2 .auditory__details { bottom: 0; left: 0; right: 0; position: absolute; padding-left: 49px; }

.auditory__item-2 img { height: 406px; max-width: none; position: relative; left: 50%; transform: translate(-50%, 0); }

.auditory__item-3 { background: #D32439; }

.auditory__picture { position: relative; z-index: 0; }

.auditory__picture img { vertical-align: top; }

.auditory__picture:before { content: ''; position: absolute; display: block; left: 0; top: 0; right: 0; bottom: 0; background: rgba(46, 52, 62, 0.1); }

.auditory__details { min-height: 153px; padding: 21px 43px; }

.auditory__slogan { top: 0; left: 0; right: 0; padding: 7px 33px; position: absolute; font-weight: bold; line-height: 34px; font-size: 22px; }

.auditory__title { font-family: Montserrat, Arial, sans-serif; font-weight: 800; font-size: 24px; }

.auditory__description { line-height: 24px; font-size: 16px; margin-top: 10px; }

.section-speakers { margin-top: 119px; padding-top: 109px; padding-bottom: 118px; }

.speakers { display: flex; margin: 33px -15px 0; }

.speakers__item { display: flex; justify-content: space-between; padding: 0 15px; width: 50%; }

.speakers__item:nth-child(2) .speakers__picture_wrap { margin-top: 27px; }

.speakers__picture { position: relative; width: 200px; }

.speakers__picture_wrap { margin-left: 48px; margin-top: 47px; }

.speakers__picture img { width: 172px; vertical-align: top; box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15); }

.speakers__details { padding: 0 0 0 47px; width: calc(100% - 248px); }

.speakers__position { font-family: Montserrat, Arial, sans-serif; font-weight: 600; line-height: 19px; font-size: 12px; color: #606060; margin-top: 4px; }

.speakers__name { color: #2693EE; font-family: Montserrat, Arial, sans-serif; font-weight: 800; font-size: 24px; }

.speakers__description { line-height: 26px; font-size: 16px; color: #606060; margin-top: 13px; }

.speakers__description p { padding: 0; }

.speakers__img { position: relative; z-index: 1; }

.speakers .socials { margin-top: 15px; }

.socials__link { color: #2693EE; font-size: 18px; margin-right: 5px; }

.anim-block__inner { transform-style: preserve-3d; }

.anim-block__inner > * { transform: translateZ(20px); }

.anim-decor { position: absolute; }

.anim-decor > * { left: 0; top: 0; position: absolute; width: 100%; height: 100%; }

.anim-1 { width: 362px; height: 242px; left: -83px; top: -43px; }

.anim-1 div { background: #2693EE; opacity: 0.2; }

.anim-2 { width: 150px; height: 150px; left: -25px; bottom: -38px; }

.anim-2 div { background: rgba(250, 0, 111, 0.2); }

.anim-3 { width: 32px; height: 32px; right: 60px; top: -52px; }

.anim-3 div { background: #2693EE; opacity: 0.5; }

.anim-4 { width: 68px; height: 68px; left: -28px; top: -32px; }

.anim-4 div { background: #2693EE; opacity: 0.6; }

.anim-5 { width: 361px; height: 228px; left: -29px; bottom: -61px; }

.anim-5 div { background: #2693EE; opacity: 0.2; }

.anim-6 { width: 168px; height: 168px; right: -39px; top: 119px; }

.anim-6 div { background: #2693EE; opacity: 0.1; }

.anim-7 { width: 114px; height: 120px; left: -49px; top: -50px; }

.anim-7 div { background: #BB6BD9; opacity: 0.2; }

.anim-8 { width: 56px; height: 63px; left: -23px; bottom: -27px; }

.anim-8 div { background: #F2C94C; opacity: 0.2; }

.anim-9 { width: 26px; height: 26px; right: -15px; top: -40px; }

.anim-9 div { background: #2693EE; opacity: 0.3; }

.anim-10 { width: 56px; height: 63px; left: -21px; top: -30px; }

.anim-10 div { background: #219653; opacity: 0.2; }

.anim-11 { width: 114px; height: 120px; left: -48px; bottom: -47px; }

.anim-11 div { background: #EB5757; opacity: 0.2; }

.anim-12 { width: 26px; height: 26px; right: -15px; top: 70px; }

.anim-12 div { background: #2F80ED; opacity: 0.3; }

.anim-13 { width: 63px; height: 80px; left: -33px; top: -29px; }

.anim-13 div { background: #EB5757; opacity: 0.4; }

.anim-14 { width: 118px; height: 122px; left: -53px; bottom: 45px; }

.anim-14 div { background: #F2994A; opacity: 0.2; }

.anim-15 { width: 38px; height: 38px; right: -20px; top: -20px; }

.anim-15 div { background: #2693EE; opacity: 0.6; }

.anim-16 { width: 255px; height: 255px; right: -46px; bottom: -39px; }

.anim-16 div { background: #2693EE; opacity: 0.1; }

.anim-17 { width: 264px; height: 277px; left: -83px; top: -67px; }

.anim-17 div { background: #BB6BD9; opacity: 0.2; }

.anim-18 { width: 109px; height: 110px; left: -47px; bottom: -41px; }

.anim-18 div { background: rgba(242, 153, 74, 0.2); }

.anim-19 { width: 54px; height: 54px; right: -82px; top: -53px; }

.anim-19 div { background: rgba(33, 150, 83, 0.2); }

.section-decor { position: relative; }

.section-decor:before { content: ''; background: rgba(196, 196, 196, 0.4); display: block; height: 2px; width: 100%; max-width: 1146px; position: absolute; left: 50%; transform: translate(-50%, 0); top: 0; }

.section-content-seminar { margin-top: 120px; padding-top: 109px; }

.content-seminar { display: flex; align-items: center; margin-top: 30px; }

.content-seminar__list { padding-right: 100px; width: 50%; position: relative; }

.content-seminar__list:before { content: ''; background: #CACCDD; display: block; width: 2px; position: absolute; left: 20px; top: 10px; bottom: 26px; }

.content-seminar__item { position: relative; padding: 0 0 0 65px; margin-bottom: 23px; }

.content-seminar__item:hover .content-seminar__counter { background: #2693EE; border-color: #2693EE; color: #fff; }

.content-seminar__item:hover .content-seminar__title { color: #2693EE; }

.content-seminar__counter { background: #fff; width: 40px; height: 40px; border: 2px solid #CACCDD; border-radius: 50%; line-height: 38px; font-size: 18px; color: #CACCDD; position: absolute; text-align: center; left: 0; top: 50%; transform: translate(0, -50%); }

.content-seminar__title { line-height: 27px; font-size: 18px; color: #000; }

.content-seminar__btn { padding-left: 67px; position: relative; margin-top: 55px; }

.content-seminar__btn:before { content: ''; background: #CACCDD; display: block; height: 2px; width: 46px; position: absolute; top: 50%; margin-top: -1px; left: 20px; }

.content-seminar .where__picture { padding: 0; max-width: 459px; }

.content-seminar .where__img img { width: 459px; }

.where__picture_wrap { padding: 0 0 5px 25px; width: 50%; }

.section-special { margin-top: 101px; padding-top: 108px; }

.special { display: flex; align-items: center; margin: 8px -15px 0; }

.special__picture, .special__details { padding: 0 15px; width: 50%; }

.special__picture { text-align: center; padding-bottom: 20px; }

.special__list { list-style: none; }

.special__item { align-items: center; font-family: Montserrat, Arial, sans-serif; font-size: 22px; color: #606060; border-radius: 10px; border: 3px dashed #2693EE; display: flex; margin-bottom: 31px; padding: 39px 63px 37px; transition: border .3s ease-in, background .3s ease-in, color .3s ease-in; }

.special__item:hover { background: #2693EE; border-style: solid; box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.28); color: #fff; }

.special__description { width: calc(100% - 72px); }

.special__counter { padding-right: 10px; width: 72px; }

.section-order { margin-top: 98px; }

.section-order .h2 { max-width: 765px; margin: 0 auto; line-height: 1.45; }

.order { display: flex; justify-content: space-between; margin: 25px 0 0; background: #FFFFFF; box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2); padding: 50px 70px 65px; }

.order__heading { color: #2693EE; font-family: Montserrat, Arial, sans-serif; font-weight: 800; font-size: 36px; margin-bottom: 39px; }

.order-list { width: 50%; padding: 22px 30px 0; }

.practice-list { list-style: none; }

.practice-list__item { line-height: 27px; font-size: 18px; margin-bottom: 16px; position: relative; padding-left: 34px; }

.practice-list__item:before { color: #2693EE; font-family: 'icomoon' !important; font-size: 20px; margin-right: 20px; position: absolute; left: 0; top: -3px; }

.form-order { width: 50%; padding: 0 15px; max-width: 400px; }

.form-order .box-field_checkbox { margin-top: 47px; }

.input--nao { overflow: hidden; padding-top: 1em; }

.box-field__input { position: relative; z-index: 1; padding: 0 0 20px; margin-bottom: 10px; overflow: hidden; vertical-align: top; }

.box-field__input label.error { color: #D32439; position: absolute; left: 0; right: 0; bottom: 0; }

.input__field { width: 100%; color: #2E343E; font-size: 18px; line-height: 27px; position: relative; display: block; padding: 19px 0 8px; border: none; border-radius: 0; background: none; font-weight: 400; }

.input__field.error ~ .graphic--nao { stroke: #D32439; }

.input__field:focus ~ .graphic--nao { stroke: #2693EE; }

.input__label { position: absolute; top: 0.95em; left: 0; display: block; width: 100%; text-align: left; padding: 0em; line-height: 27px; font-size: 18px; pointer-events: none; transform-origin: 0 0; transition: transform 0.2s 0.15s, color 1s; transition-timing-function: ease-out; }

.graphic--nao { position: absolute; bottom: 15px; left: 0; fill: none; stroke: #CACCDD; stroke-width: 1.5px; pointer-events: none; transition: transform 0.7s, stroke 0.7s; transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1); }

.input__field:focus + .input__label, .input--filled .input__label { transform: translate3d(0, -15px, 0) scale3d(0.7, 0.7, 1); }

.input__field:focus ~ .graphic--nao, .input--filled .graphic { transform: translate3d(-66.6%, 0, 0); }

.box-field_btn { margin-top: 31px; }

.box-field_checkbox { margin-top: 44px; position: relative; }

.checkbox { opacity: 0; position: absolute; left: 0; top: 0; }

.checkbox:checked + .check-label:after { background: #2693EE; }

.check-label { color: #606060; cursor: pointer; display: block; font-weight: 300; line-height: 18px; font-size: 12px; position: relative; padding-left: 34px; }

.check-label:before, .check-label:after { content: ''; display: block; position: absolute; }

.check-label:before { width: 20px; height: 20px; border: 1px solid #2693EE; top: 2px; left: 0; }

.check-label:after { width: 12px; height: 12px; background: transparent; transition: background .3s ease-in; top: 6px; left: 4px; }

.check-label a { color: inherit; }

.btn-video { margin-top: 40px; text-align: center; }

.section-industries { padding: 100px 0 136px; }

.section-industries .h2 { max-width: 880px; margin: 0 auto; }

.section-industries .wrapper { z-index: 1; }

.industries-wrap { position: relative; }

.industries-wrap:before { content: ''; background: #EEE; top: 101px; bottom: 124px; left: 0; right: 0; position: absolute; z-index: 0; }

.industries { display: flex; flex-wrap: 33.33%; margin: 0 -15px; padding: 23px 0 0; }

.industries__column { width: 33.33%; padding: 0 15px; display: flex; justify-content: space-between; flex-direction: column; }

.industries__item { background: #2693EE; margin-bottom: 51px; width: 100%; display: flex; justify-content: center; flex-direction: column; }

.industries__item:last-child { margin-bottom: 0; }

.industries__item img { vertical-align: top; width: 100%; }

.industries__item_2 .industries__title { padding-top: 52px; padding-bottom: 53px; }

.industries__item_3 { height: 100%; }

.industries__item_3 .industries__title { padding-top: 58px; padding-bottom: 54px; }

.industries__item_4 { margin-bottom: 49px; }

.industries__item_4 .industries__title { padding-top: 27px; padding-bottom: 28px; }

.industries__item_5 .industries__title { padding-top: 27px; padding-bottom: 27px; }

.industries__title { color: #fff; font-weight: 800; font-size: 24px; padding: 25px 38px 24px; font-family: Montserrat, Arial, sans-serif; flex-grow: 4; width: 100%; display: flex; align-items: center; }

.section-clients { margin-top: 106px; }

.clients { display: flex; flex-wrap: wrap; margin: 30px -15px 0; align-items: center; justify-content: center; }

.clients__item { padding: 0 15px; margin: 0 0 28px; text-align: center; width: 25%; }

.wow { opacity: 0; }

.ios .wow { opacity: 1; }

.animated { animation-duration: 1s; animation-fill-mode: both; }

.animated.infinite { animation-iteration-count: infinite; }

@keyframes fadeInUp { 0% { opacity: 0;
    transform: translate3d(0, 50%, 0); }
  to { opacity: 1;
    transform: none; } }

.fadeInUp { animation-name: fadeInUp; }

@keyframes zoomIn { 0% { opacity: 0.5;
    transform: scale3d(0.8, 0.8, 0.8); }
  50% { opacity: 1; } }

.zoomIn { animation-name: zoomIn; }

@keyframes zoomInDown { 0% { opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% { opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes fadeInRight { 0% { opacity: 0;
    transform: translate3d(100%, 0, 0); }
  to { opacity: 1;
    transform: none; } }

.fadeInRight { animation-name: fadeInRight; }

.scrollDisabled { overflow: hidden; position: fixed !important; margin-top: 0; width: 100%; z-index: 0; }

.scrollDisabled.web.compensate-for-scrollbar { width: -webkit-calc(100% - 17px); width: calc(100% - 17px); }

.scrollDisabled.web.compensate-for-scrollbar header { padding-right: 17px; }

.hide-desc { display: none; }

@media screen and (max-width: 1150px) { .industries__title { font-size: 22px; } }

@media screen and (max-width: 1040px) { .industries__title { font-size: 20px; } }

@media screen and (max-width: 991px) { .container { padding-left: 30px; padding-right: 30px; }
  h1, .h1 { font-size: 32px; }
  h2, .h2 { font-size: 30px; }
  .main-block__subtitle, .main-block__date { font-size: 23px; }
  .auditory { justify-content: center; flex-wrap: wrap; }
  .auditory__item { max-width: 382px; width: 50%; }
  .auditory__item:first-child { order: 1; }
  .auditory__item:nth-child(2) { order: 3; }
  .auditory__item:nth-child(3) { order: 2; }
  .order { display: block; }
  .order-list, .form-order { margin: 0 auto; max-width: 500px; width: 100%; }
  .form-order { margin-top: 30px; }
  .box-field_btn { text-align: center; }
  .special__item { padding: 25px 30px 23px; }
  .content-seminar__list { padding-right: 15px; }
  .where__picture { padding: 0; }
  .industries { margin-left: -10px; margin-right: -10px; }
  .industries__column { padding-left: 10px; padding-right: 10px; }
  .industries__item, .industries__item_4 { margin-bottom: 30px; }
  .industries__title { padding-left: 20px; padding-right: 20px; font-size: 18px; }
  .industries__item_2 .industries__title { padding-top: 42px; padding-bottom: 43px; }
  .anim-17 { left: -23px; }
  .anim-18 { left: -17px; }
  .anim-19 { right: -22px; top: -64px; }
  .where__heading { font-size: 27px; }
  .where__heading strong { font-size: 36px; }
  .where__heading span { font-size: 18px; }
  .where__logo { max-width: 33.33%; } }

@media screen and (min-width: 768px) and (max-width: 1180px) { .speakers__item { display: block; }
  .speakers__details { margin: 60px auto 0; max-width: 400px; width: 100%; }
  .speakers__picture { margin: 0 auto; } }

@media screen and (min-width: 768px) and (max-width: 991px) { .anim-1 { width: 252px; height: 182px; left: -33px; top: -43px; }
  .anim-5 { width: 261px; height: 178px; }
  .anim-3 { right: 10px; }
  .content-seminar .where__img img { width: 389px; }
  .content-seminar .where__picture { max-width: 389px; }
  .where__heading { font-size: 25px; }
  .where__heading strong { font-size: 33px; }
  .where__heading span { font-size: 16px; } }

@media screen and (max-width: 767px) { .container { padding-left: 15px; padding-right: 15px; }
  h1, .h1 { font-size: 24px; }
  h2, .h2 { font-size: 22px; padding-bottom: 25px; }
  .video-bg video { display: none; }
  .main-block__subtitle, .main-block__date { font-size: 18px; }
  .section-main { display: flex; align-items: center; justify-content: center; padding: 140px 0 75px; }
  .section-main .container { display: block; height: auto; }
  .main-block__links { margin-top: 58px; }
  .btn { height: 49px; line-height: 45px; font-size: 15px; }
  .block-links_item { margin-bottom: 15px; }
  .special { display: block; }
  .special__picture, .special__details { width: 100%; }
  .special__picture { text-align: center; }
  .special__details { margin-top: 30px; }
  .special__slogan { font-size: 17px; }
  .content-seminar, .speakers { display: block; }
  .content-seminar .where__picture, .speakers .where__picture { width: 100%; }
  .content-seminar__list { padding-right: 0; width: 100%; }
  .speakers__item { width: 100%; }
  .auditory__title { font-size: 17px; }
  .auditory__description { font-size: 14px; line-height: 1.25; }
  .speakers__item { align-items: center; margin-bottom: 40px; }
  .speakers__name { font-size: 19px; }
  .speakers__description { font-size: 14px; }
  .review-wrap, .where { display: block; }
  .review__description, .where__item { width: 100%; }
  .review__picture { margin: 0 auto; text-align: center; width: 100%; max-width: 460px; }
  .review__author { font-size: 17px; }
  .review__ratingvalue { font-size: 20px; }
  .section-where h2 { padding-bottom: 30px; }
  .where { margin-top: 0; }
  .where__heading { padding: 20px 15px; }
  .anim-17 { top: -27px; }
  .anim-19 { top: -14px; }
  .where__item { margin: 0 auto 70px; }
  .where__title { font-size: 17px; }
  .section-special { margin-top: 61px; padding-top: 69px; }
  .section-auditory { margin-top: 30px; padding-top: 55px; }
  .section-content-seminar { margin-top: 69px; padding-top: 49px; }
  .auditory { margin-top: 0; }
  .review__img { margin: 0; }
  .review__img img { display: inline-block; }
  .clients__item { margin-bottom: 30px; width: 33.333%; }
  .arrows-slider { margin-top: 30px; }
  .content-seminar__title { font-size: 14px; }
  .where__picture { margin: 40px auto 0; }
  .reviews { margin-top: 30px; margin-right: 0; }
  .reviews-slider { margin-right: 0; }
  .review__description { padding-right: 0; }
  .order { padding: 29px 20px 35px; }
  .order__heading { font-size: 20px; }
  .order-list { padding: 0; }
  .input__field, .input__label { font-size: 14px; }
  .practice-list__item { font-size: 15px; line-height: 20px; }
  .practice-list__item:before { top: 1px; }
  .where__picture_wrap { margin-top: 65px; padding: 0; width: 100%; }
  .content-seminar .where__picture { margin: 0 auto; }
  .section-experience { padding-top: 40px; }
  .reviews { padding: 22px 20px; }
  .review__text { padding-bottom: 15px; }
  .section-order { margin-top: 48px; }
  .section-order .h2 { padding-bottom: 25px; }
  .btn { min-width: 180px; }
  .section-clients { margin-top: 56px; }
  .clients { margin-top: 0; }
  .content { padding-bottom: 25px; }
  .section-speakers { margin-top: 89px; padding-top: 47px; padding-bottom: 30px; }
  .anim-1 { width: 80%; height: 60%; left: -15%; top: -43px; }
  .anim-2 { width: 45%; height: 45%; left: -10%; }
  .anim-5 { width: 80%; height: 59%; left: -8%; bottom: -12%; }
  .anim-16 { width: 45%; height: 50%; right: -3%; bottom: -30px; }
  .anim-14 { width: 30%; height: 122px; left: -5%; bottom: 3%; }
  .section-where { padding-top: 56px; }
  .content-seminar__list:before { top: 30px; }
  .reviews-slider .slick-list { overflow: hidden; }
  .reviews-slider { margin-left: -15px; margin-right: -15px; }
  .reviews-slider__item { padding: 0 15px; }
  .where__heading { font-size: 17px; }
  .where__heading strong { font-size: 23px; }
  .where__heading span { font-size: 14px; }
  .auditory__details { padding: 20px 20px 25px; min-height: 0; }
  .auditory__item-2 { min-height: 0; }
  .auditory__item-2 img { height: auto; }
  .content-seminar { margin-top: 10px; }
  .section-industries { padding: 40px 0; }
  .industries { flex-wrap: wrap; }
  .industries__column { margin-bottom: 30px; width: 50%; }
  .industries__column:nth-child(3) { flex-direction: row; width: 100%; }
  .industries__column:nth-child(3) .industries__item { margin: 0 10px 0 0; width: calc(50% - 10px); }
  .industries__column:nth-child(3) .industries__item:nth-child(2) { margin: 0 0 0 10px; }
  .scroll-down { display: none; } }

@media screen and (max-width: 560px) { .auditory__item { width: 100%; }
  .auditory__item:first-child { order: 1; }
  .auditory__item:nth-child(2) { order: 2; }
  .auditory__item:nth-child(3) { order: 3; }
  .speakers__item { display: block; }
  .speakers__picture { text-align: center; max-width: 172px; margin: 0 auto; width: 100%; }
  .speakers__details { margin-top: 15px; width: 100%; }
  .speakers__name, .speakers__position { text-align: center; }
  .speakers .socials { text-align: center; }
  .clients__item { width: 50%; }
  .speakers__picture_wrap { margin-left: 0; }
  .speakers__item + .speakers__item { margin-top: 20px; }
  .speakers__details { padding-left: 0; }
  .where__heading { display: block; }
  .where__details { text-align: center; padding-right: 0; }
  .where__logo { margin: 20px auto 8px; max-width: 100%; padding-left: 0; } }

@media screen and (max-width: 480px) { .industries__column { margin-left: auto; margin-right: auto; max-width: 290px; width: 100%; }
  .industries__column:nth-child(3) { display: block; }
  .industries__column:nth-child(3) .industries__item { margin: 0 0 30px; width: 100%; }
  .industries__column:nth-child(3) .industries__item:nth-child(2) { margin-left: 0; }
  .hide-desc { display: inline-block; }
  .hide-mob { display: none; }
  .industries__title, .industries__item_2 .industries__title, .industries__item_3 .industries__title, .industries__item_4 .industries__title, .industries__item_5 .industries__title { padding-top: 20px; padding-bottom: 25px; } }
